import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Typography, Grid } from '@mui/material'
import { TextField, ModelMenuSelectorField, ModelAutocompleteField } from '@front/squirtle'

const Title = props =>
  <Grid item xs={12}>
    <Typography variant={'h5'} sx={{ textAlign: 'center' }}> {I18n.t('schedule.search')} </Typography>
  </Grid>

const Row1 = ({ type, ...props }) =>
  <Grid container item xs={12} spacing={1} >
    <Grid item xs={6}>
      <ModelAutocompleteField
        name={'schedule_id'}
        label={I18n.t('name.label', { count: 1 })}
        model_name={'schedule'}
        searchMode={'search'}
        searchQuery={'contains'}
        autocompleteKey={'name'}
        labelKeys={['name']}
        config={{
          initial_filter: {},
          forced_filter: { type },
          watchers: [type],
        }}
      />
    </Grid>
    <Grid item xs>
      <ModelMenuSelectorField
        name='type'
        label={I18n.t('scheduletype.label', { count: -1 })}
        model_name='scheduletype'
        labelKeys={['name_translated']}
        loadOnMount
        searchMode={'search'}
        multiple
      />
    </Grid>
  </Grid>

const SearchForm = props =>
  <Grid container justifyContent={'center'}>
    <Grid item xs={12} sm={6}>
      <Box sx={{ borderRadius: 1, boxShadow: 6, p: 1 }}>
        <Grid container rowSpacing={3} justifyContent={'center'}>
          <Title />
          <Row1 />
        </Grid>
      </Box>
    </Grid>
  </Grid>

export default React.memo(SearchForm)
